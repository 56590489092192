import React, { useRef } from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Download from "../../client/compositions/download"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import ProductDetails from "../../client/compositions/product-details"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { ProductsTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

const premierFeatures = [
  {
    name: "Single",
    image: "/single/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Metal Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Column Width",
        content: "1 ,2 and 3 bottles",
      },
      {
        title: "Shelf Depth",
        content: "Single (4”)",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 72 bottles",
          "4’ column up to 99 bottles",
          "8’ column up to 198 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/single/brushed-stainless-steel/product-01.medium.jpg",
          "/single/brushed-stainless-steel/product-02.medium.jpg",
          "/single/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/single/black-satin/product-01.medium.jpg",
          "/single/black-satin/product-02.medium.jpg",
          "/single/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },

  {
    name: "Double",
    image: "/double/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Metal Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Column Width",
        content: "1 ,2 and 3 bottles",
      },
      {
        title: "Shelf Depth",
        content: "Double (9.25”)",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 72 bottles",
          "4’ column up to 99 bottles",
          "8’ column up to 198 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/double/brushed-stainless-steel/product-01.medium.jpg",
          "/double/brushed-stainless-steel/product-02.medium.jpg",
          "/double/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/double/black-satin/product-01.medium.jpg",
          "/double/black-satin/product-02.medium.jpg",
          "/double/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Triple",
    image: "/triple/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Metal Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Column Width",
        content: "1 ,2 and 3 bottles",
      },
      {
        title: "Shelf Depth",
        content: "Triple (12.25”)",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 72 bottles",
          "4’ column up to 99 bottles",
          "8’ column up to 198 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/triple/brushed-stainless-steel/product-01.medium.jpg",
          "/triple/brushed-stainless-steel/product-02.medium.jpg",
          "/triple/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/triple/black-satin/product-01.medium.jpg",
          "/triple/black-satin/product-02.medium.jpg",
          "/triple/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Cascade",
    image: "/cascade/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Metal Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Column Width",
        content: "1 ,2 and 3 bottles",
      },
      {
        title: "Shelf Depth",
        content: "Single (4”), double (9.25”) & triple (12.25”)",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 72 bottles",
          "4’ column up to 99 bottles",
          "8’ column up to 198 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/cascade/brushed-stainless-steel/product-01.medium.jpg",
          "/cascade/brushed-stainless-steel/product-02.medium.jpg",
          "/cascade/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/cascade/black-satin/product-01.medium.jpg",
          "/cascade/black-satin/product-02.medium.jpg",
          "/cascade/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
  {
    name: "Presentation",
    image: "/presentation/feature-thumbnail.small.jpg",
    details: [
      {
        title: "Modular Column Heights",
        content: "3’, 4’ and 8’",
      },
      {
        title: "Metal Shelves Per Modular Column",
        content: "8, 11, & 22",
      },
      {
        title: "Column Width",
        content: "1 ,2 and 3 bottles",
      },
      {
        title: "Shelf Depth",
        content: "Triple (12.25”)",
      },
      {
        title: "Anchoring Style",
        content: "Floor-to-ceiling",
      },
      {
        title: "Bottle Capacity Per Column",
        content: [
          "3’ column up to 72 bottles",
          "4’ column up to 99 bottles",
          "8’ column up to 198 bottles",
        ],
      },
    ],
    finishes: [
      {
        name: "Brushed Stainless Steel",
        image: "/finish-brushed-stainless-steel.jpg",
        gallery: [
          "/presentation/brushed-stainless-steel/product-01.medium.jpg",
          "/presentation/brushed-stainless-steel/product-02.medium.jpg",
          "/presentation/brushed-stainless-steel/product-03.medium.jpg",
        ],
      },
      {
        name: "Black Satin",
        image: "/finish-black-satin.jpg",
        gallery: [
          "/presentation/black-satin/product-01.medium.jpg",
          "/presentation/black-satin/product-02.medium.jpg",
          "/presentation/black-satin/product-03.medium.jpg",
        ],
      },
    ],
  },
]

/**
 * Product: Premier Page
 * @see https://zpl.io/2vJBDrn
 */
function ProductPremierPage() {
  const mainSlider = useRef()

  return (
    <article id="page-product-premier" className="page-content product-page">
      {quickMenu}

      <Banner
        title={
          <>
            Premier
            <br />
            Collection
          </>
        }
        content={
          <>
            <p>
              Maximum capacity and
              <br /> showcase ability with
              <br /> minimal footprint.
            </p>
          </>
        }
        className="flipped"
      >
        <DecorationFluid image="product-premier/banner.jpg" />
      </Banner>

      <ProductDetails
        data={{
          rootImageDir: "/product-premier/features",
          features: premierFeatures,
        }}
      />

      <Row id="display-styles">
        <Block>
          <h4 className="title">
            Display
            <br />
            Styles
          </h4>
          <ul className="items">
            <li className="item">
              <h4 className="title">Standard</h4>
              <DecorationFluid image="product-premier/display-01.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Cascade</h4>
              <DecorationFluid image="product-premier/display-02.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Presentation</h4>
              <DecorationFluid image="product-premier/display-03.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Standard (Connected)</h4>
              <DecorationFluid image="product-premier/display-04.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Cascade (Connected)</h4>
              <DecorationFluid image="product-premier/display-05.medium.jpg" />
            </li>
            <li className="item">
              <h4 className="title">Presentation (Connected)</h4>
              <DecorationFluid image="product-premier/display-06.medium.jpg" />
            </li>
          </ul>
        </Block>
      </Row>

      <Row id="slim-fit" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">Sleek Design</h2>
          <div className="content">
            <p>
              Premier modular metal shelving can be customized to create the
              ultimate wine showcasing experience. Premier can be customized to
              meet your desired height, width and depth. Shelves can easily be
              configured to showcase wine in horizontal and angled presentation
              modes. Integrate shelves with wood to create a transitional look.
            </p>
          </div>
          <Button to="contact" image="icons/arrow-right.svg">
            Contact
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "46vw",
              height: "52vw",
            }}
            sizes={[
              { w: "67%", h: "83%" },
              { w: "50%", h: "49%" },
            ]}
          >
            <DecorationFluid image="product-premier/fit-01.medium.jpg" />
            <DecorationFluid image="product-premier/fit-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Download
        buttons={[
          { label: "Catalogue", url: "http://google.com" },
          {
            label: "CAD files",
            url: "https://www.dropbox.com/sh/nkyg72vv7pbptr4/AAB-m54UlsEmPXwp011Kr6Hua?dl=1",
          },
        ]}
      >
        <DecorationFluid image="/product-premier/contact.small.jpg" />
      </Download>
      <ProductsTaglineMenu productSlug="product-premier" />
    </article>
  )
}

export default ProductPremierPage
